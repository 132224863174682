export default {
  props: {
    current: {
      type: Number,
      required: true,
    },
    last: {
      type: Number,
      required: true,
    },
  },
  methods: {
    withPage (page) {
      const path = this.$route.path
      const query = new URLSearchParams({ ...this.$route.query, page }).toString()

      return `${path}?${query}`
    },
  }
}
