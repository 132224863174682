<template>
  <div>
    <loading v-if="displayLoading"></loading>

    <header class="d-flex justify-content-between align-items-center">
      <span class="text-left">
        <button v-if="false" class="btn text-white">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
            <path
              d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"
            />
          </svg>
          回選單
        </button>
      </span>
      <h1 class="py-2">客戶清單</h1>
      <span class="text-right">
        <button v-if="false" class="btn-message text-white message">
          <IconCommentDots :height="15" />
          <b-badge pill variant="danger" class="">6</b-badge>
        </button>
      </span>
    </header>

    <ul
      v-if="false"
      class="customer-tab nav nav-tabs d-flex justify-content-between"
    >
      <li class="nav-item flex-fill">
        <a
          :class="`nav-link ${currentTab == 'new' && 'active'}`"
          @click="
            () => {
              currentTab = 'new';
            }
          "
          >新顧客</a
        >
      </li>
      <li class="nav-item flex-fill">
        <a
          :class="`nav-link ${currentTab == 'old' && 'active'}`"
          @click="
            () => {
              currentTab = 'old';
            }
          "
          >老顧客</a
        >
      </li>
      <li class="nav-item flex-fill">
        <a
          :class="`nav-link ${currentTab == 'lost' && 'active'}`"
          @click="
            () => {
              currentTab = 'lost';
            }
          "
          >失顧聯客</a
        >
      </li>
    </ul>

    <div class="px-3 pt-3 pb-2">
      <div class="search mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
          />
        </svg>
        <b-form-input
          type="search"
          class="field__input"
          placeholder="姓名搜尋"
          v-model.trim="keyword"
        ></b-form-input>
      </div>

      <div class="filter" v-if="false">
        <button class="btn btn-xs font-weight-bold" v-b-modal.modal-filter>
          新增篩選
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
            />
          </svg>
        </button>

        <b-modal
          id="modal-filter"
          size="sm"
          header-class="border-bottom-0"
          centered
        >
          <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->

            <h5 class="m-0 align-self-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                style="height: 20px"
              >
                <path
                  d="M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L418.7 226.7C443.7 251.7 443.7 292.3 418.7 317.3L285.3 450.7C260.3 475.7 219.7 475.7 194.7 450.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z"
                />
              </svg>
              新增篩選
            </h5>
            <b-button
              size="sm"
              variant="link"
              class="text-dark"
              @click="close()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                style="height: 20px"
              >
                <path
                  d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                />
              </svg>
            </b-button>
          </template>
          <b-row>
            <b-col
              cols="6"
              v-for="(t, idx) in tagOptions"
              :key="idx"
              class="my-2"
            >
              <b-button
                block
                size="md"
                class="py-2 px-3 text-left font-weight-bold d-flex justify-content-between"
                pill
                :variant="tags.includes(t) ? 'dark' : 'secondary'"
                @click="
                  () => {
                    var el = tags;
                    el.includes(t) ? el.splice(el.indexOf(t), 1) : el.push(t);
                  }
                "
              >
                <span>{{ t }}</span>
                <svg
                  v-if="tags.includes(t)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  style="height: 15px; fill: #fff"
                >
                  <path
                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                  />
                </svg>
              </b-button>
            </b-col>
          </b-row>

          <template #modal-footer="{ ok, cancel }">
            <div class="d-flex justify-content-between w-100">
              <b-button
                size="lg"
                variant="outline-dark mr-3 w-100"
                @click="cancel"
                >取消</b-button
              >
              <b-button size="lg" variant="primary w-100" @click="ok"
                >確認</b-button
              >
            </div>
          </template>
        </b-modal>
      </div>
    </div>

    <b-list-group>
      <template v-if="customers">
        <b-list-group-item
          v-for="(i, index) in customers"
          :key="`item` + index"
          class="d-flex align-items-center p-3"
          :to="{
            name: 'LiffSalesCustomer',
            params: {
              id: i.id,
            },
          }"
        >
          <b-avatar
            class="mr-3 s-flex-center"
            :src="i.avatar_url"
            size="50px"
          ></b-avatar>
          <div class="mr-auto font-weight-bold">
            {{ i.name }}
            <b-badge
              pill
              variant="light"
              class="text-dark"
              v-for="t in i.tags"
              :key="t"
            >
              {{ t }}
            </b-badge>
            <div class="text-muted small">
              {{ format(new Date(i.bound_at), "yyyy/MM/dd HH:mm") }} 建立
            </div>
          </div>

          <!-- <b-button size="xs" variant="primary">發訊息</b-button> -->

          <IconChevronRight :height="18" />
        </b-list-group-item>
      </template>
      <b-list-group-item v-else class="empty">
        <img src="./images/empty.svg" />
        <div class="mt-3 text-dark small">目前沒有名單</div>
      </b-list-group-item>
    </b-list-group>
    <div class="sticky-bottom">
      <Pagination :current="page" :last="lastPage" class="position-static" />
    </div>
  </div>
</template>

<script>
//import liff from "@line/liff"
import format from "date-fns/format";
import LiffChecker from "@/utils/LiffChecker";
//import _ from "lodash";
import axios from "axios";
import IconChevronRight from "@/components/Icon/IconChevronRight.vue";
import IconCommentDots from "@/components/Icon/IconCommentDots.vue";
import Pagination from "@/components/Page/Liff/Sales/Pagination";

const PER_PAGE_AMOUNT = 10;

export default {
  components: { IconChevronRight, IconCommentDots, Pagination },
  data() {
    return {
      buttonLoading: false,
      displayLoading: true,
      currentTab: null,
      customers: [],
      allCustomers: [],
      tagOptions: [
        "vip",
        "金級",
        "銀級",
        "未選取標籤1",
        "未選取標籤2",
        "已選取標籤",
      ],
      tags: [],
      keyword: "",
      lastPage: 1,
    };
  },
  computed: {
    page() {
      return +this.$route.query.page || 1;
    },
  },
  created() {
    this.init();
  },
  watch: {
    page: "changePage",
    keyword: "filterCustomer",
  },
  methods: {
    format,
    async init() {
      let _this = this;

      const orgCode = this.$route.params.org_code;
      const checker = new LiffChecker(orgCode);

      _this.lineData = await checker.getLineData();

      const url = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/sales/customers`;

      await axios
        .get(url, {
          params: {
            page: this.page,
          },
          headers: {
            Authorization: `Bearer ${_this.lineData.accessToken}`,
          },
        })
        .then((response) => {
          _this.customersData = response.data.data
          _this.allCustomers = _this.customersData
          _this.changePage();
        })
        .catch((error) => {
          console.log(error);
        });
      _this.displayLoading = false;
    },
    filterCustomer() {
      this.allCustomers = this.customersData.filter((x) => {
        return (
          this.keyword == '' ||
          x.name.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0
        );
      });
      this.$router.replace({ page:1 })
      this.changePage()
    },
    changePage() {
      this.customers = [];
      this.lastPage = Math.ceil(this.allCustomers.length / PER_PAGE_AMOUNT) || 1;
      const start = (this.page - 1) * PER_PAGE_AMOUNT;
      const end = start + PER_PAGE_AMOUNT;
      for (let i = start; i < end && i < this.allCustomers.length; i++) {
        const item = this.allCustomers[i];
        this.customers.unshift(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  input {
    padding-left: 45px;
  }
  svg {
    position: absolute;
    left: 18px;
    top: 14px;
    height: 15px;
  }
}
.filter {
  svg {
    fill: #1e88e5;
    height: 12px;
  }
}

.btn-message {
  position: relative;
  padding: 5px 15px 10px 10px;

  .badge {
    padding: 0px 5px;
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 10rem;
    font-size: 10px;
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px;
  img {
    width: 80px;
  }
}

.sticky-bottom {
  background-color: var(--liff-layout_bg_color);
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
</style>
