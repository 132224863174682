<template>
  <ul class="s-pagination">
    <li class="s-arrow" :disabled="current <= 1">
      <router-link :to="withPage(current - 1)">&lt;</router-link>
    </li>
    <li class="s-text-gray-darker">
      {{ current }}
    </li>
    <li class="s-text-gray-light">/</li>
    <li class="s-text-gray-darker">
      {{ last }}
    </li>
    <li class="s-arrow" :disabled="current >= last">
      <router-link :to="withPage(current + 1)">&gt;</router-link>
    </li>
 </ul>
</template>

<script>
  import pagination from '@/mixins/liff/pagination'

  export default {
    mixins: [pagination],
  }
</script>

<style scoped>
  .s-pagination {
    position: sticky;
    bottom: 0;
    padding: 0.5rem 0;
    background-color: var(--s-gray-lighter);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .s-pagination li:not(:last-child) {
    margin-right: 0.75rem;
  }

  .s-pagination .s-arrow a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
  }

  .s-pagination .s-arrow a {
    color: var(--s-secondary);
    background-color: var(--s-white);
    border: 1px solid var(--s-secondary);
  }

  .s-pagination .s-arrow[disabled] a {
    color: var(--s-gray-light);
    border-color: var(--s-gray-light);
    pointer-events: none;
  }
</style>
